@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
.list-item{
    padding:8px;
    border : 1px solid lightgray;
    border-radius: 2px;
    /* padding: 8px; */
    width: 30vh;
    margin-bottom: 8px;
    background: linear-gradient(lightgray 0 0) no-repeat calc(200% - var(--p, 0%))
    100% / 200% var(--p, 0.08em);
  transition: 0.3s var(--t, 0s),
    background-position 0.3s calc(0.3s - var(--t, 0s));
    
}

.list-item:hover{
    --p: 100%;
    --t: 0.3s;
    color: #fff;
}

.list{
  margin:auto;
  padding-left: 7px;
  width: 40vh;
}
.listbox{
    width: 40vh;
    background-color: rgb(243, 255, 255);
    border: 2px solid rgb(243, 255, 255);
    box-shadow: 0px 0 3px #9ab6b9;
    border-radius : 4px;
    display: flex;
    margin-left:auto;
    margin-right: auto;
    /* border-color: aqua; */
    /* background-color: aquamarine; */
    
}

@import url(https://fonts.googleapis.com/css?family=Roboto:700);
/* html, body{background : white; font-family : Roboto; height: 100%; width: 100%; margin: 0;} */


.button {
  background: white;
  /* margin : 20px auto; */
  /* width : 200px; */
  /* height : 50px; */
  overflow: hidden;
  text-align : center;
  transition : .2s;
  cursor : pointer;
  border-radius: 3px;
  box-shadow: 0px 1px 2px 1px rgba(0,0,0,.2);
  background-color: rgb(243, 255, 255);
}

.buttonStat {
    background: lightgoldenrodyellow;
    /* margin : 20px auto; */
    /* width : 200px; */
    /* height : 50px; */
    overflow: hidden;
    text-align : center;
    transition : .2s;
    cursor : pointer;
    border-radius: 3px;
    box-shadow: 0px 1px 2px 1px rgba(0,0,0,.2);

  }

.buttonStatComp {
    background:#E8F4EA;
    /* margin : 20px auto; */
    /* width : 200px; */
    /* height : 50px; */
    overflow: hidden;
    text-align : center;
    transition : .2s;
    cursor : pointer;
    border-radius: 3px;
    box-shadow: 0px 1px 2px 1px rgba(0,0,0,.2);

  }
  
.btnTwo {
  position : relative;
  width : 200px;
  height : 100px;
  margin-top: -100px;
  padding-top: 2px;
  /* background : rgb(255, 255, 255); */
  left : -230px;
  transition : .3s;
}

.btnTextFin {
    color : green;
    transition : .3s;
  }

.btnTextRun {
    color :darkgoldenrod;
    transition : .3s;
    font-family: 'Poppins',sans-serif;
}
.btnText {
  color : gray;
  transition : .3s;
  font-family: 'Poppins',sans-serif;
  font-size: small;
  font-weight: 800;
}
.btnText2 {
  margin-top : 49px;
  margin-right : -130px;
  color : #FFF;
  font-family: 'Poppins',sans-serif;
}
.button:hover .btnTwo{ /*When hovering over .button change .btnTwo*/
  left: -130px;
}
.button:hover .btnText{ /*When hovering over .button change .btnText*/
  margin-left : 65px;
}
.button:active { /*Clicked and held*/
  box-shadow: 0px 5px 6px rgba(0,0,0,0.3);
}

.textjob{
  font-family: 'Poppins',sans-serif;
}

.center {
  display: block;
  margin-left: 40%;
  margin-top: 17%;
  margin-right: auto;
}

.list-title{
  display: flex;
  justify-content: space-between;
}