@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');



.headlogo{
    /* text-align: center; */
    display: flex;
    justify-content: center;
    height: 110px;
    padding: 2%;
    font-family: 'Poppins',sans-serif;
    font-size: large;
    color: azure;
    text-shadow: 0px 0px 7px #1b0836;

}

.heading{

  font-family: 'Poppins',sans-serif;
  font-weight: bold;
}

.dual-container {
    width: 90%;
    height: 400px;
    /* background: aqua; */
    margin: auto;
    padding: 10px;
  }
  
  .one {
    width: 50%;
    height: 400px;
    /* background: red; */
    float: left;
    color: azure;
  }
  
  .two {
    width: 50%;
    text-align: center;
    margin-left:50%;
    height: 400px;
    color: azure;
    /* background: antiquewhite; */
  }

  .login{
    position:fixed;
    bottom:0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin-left: 47%; */
    align-items: center;
    text-align: center;
    width:100%;
    /* sheight: 90px; */
  }
  