@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');



.text{
    /* text-align: center; */
    display: flex;
    justify-content: center;
    padding: 30px;
    font-family: 'Poppins',sans-serif;
    font-weight: normal;
    color: azure;
    /* text-shadow: 0 0 15px #8e54e9; */
    text-shadow: 0px 0px 15px #2a1250;

}

