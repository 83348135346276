.molstar{
    align-items: center;
    justify-content: center;
    padding: auto;
    padding-top: 24%;
}

.heading{
    text-align: center;
    align-items: center;
    justify-content: center;
}

.aspectwrapper {
    display: inline-block; /* shrink to fit */
    width: 100%;           /* whatever width you like */
    position: relative;    /* so .content can use position: absolute */
  }
  .aspectwrapper::after {
    padding-top: 56.25%; /* percentage of containing block _width_ */
    display: block;
    content: '';
}

.content {
    position: absolute;
    top: 0; bottom: 0; right: 0; left: 0;  /* follow the parent's edges */            /* just so you can see the box */
    justify-content: center;
  }