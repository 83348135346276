html {
    height: 100%;
  }
  
  /* body {
    height: 100%;
    margin: 0;
    background-repeat: no-repeat;
    background-image: linear-gradient(#5b1492, #882973, #ce7eb5);
  }
   */
  .btn {
    text-align: center;
    position: absolute;
    top: 30%;
    left: 42%;
    zoom: 1.5;
  }
  
  button {
    border-radius: 10px;
    background-color: grey;
    outline: none;
  }
  
  button:hover {
    background-color: white;
  }

  .close {
    color: white;
    float: right;
    cursor: pointer;
  }
  
  .modal {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(1px);
  }
  
  .modal_content {
    background-color: white;
    position: absolute;
    top: 20%;
    left: 30%;
    width: 40%;
    padding: 20px;
    border-radius: 5px;
    border: 2px solid black;
  }
  

  .loginicon {
    position: absolute;
    width: 80px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bolder;
    color: black;
    height: 40px;
    left: 85%;
    top: 10px;
    background:#2bc6ff;
    border-radius: 24px;
    cursor: pointer;
  }
  
  /* .loginicon:hover{
    color: white;
  }
   */
  .login-form{
    width: 100%;
    display: flex;
    margin-top: 10%;
    justify-content: center;
    align-items: center;
    min-height: 20vh;
  }
  
  .form-box{
    width: 100%;
    margin-top: 7vh;
    opacity:85%;
    background: rgb(0, 0, 0);
    width: 30%;
    padding: 15px;
    /* padding-right: 20px; */
    /* padding-left: 20px; */
    border-radius: 20px;
    box-shadow: 10px 10px 10px 1px rgba(0, 0, 0, 0.2);
  }
  
  .login-text{
    width: 10%;
    color: white;
    border-bottom: 2px solid white;
    line-height: 30px;
    width: 100%;
    font-weight: bolder;
    text-align: center;
    display: flex;
    justify-content: center;
    padding-top: 5vh;
  }
  
  .label {
    color: white;
    display: flex;
    margin: auto;
    justify-content: center;
  }
  
  .login-box{
    border: 1px solid rgb(137, 129, 129);
    display: flex;
    border-radius: 10px;
    padding: 3px;
    letter-spacing: 1px;
    font-weight: 200;
    height: 20px;
    margin: auto;
    font-size: 11pt;
    /* background: transparent; */
    /* transition: 1s; */
    outline: none;
  }
  
  .login-btn{
    width: 75%;
    justify-content: center;
    align-items: center;
    background-color: rgb(48,172,251);
    color: white;
    margin-top: 5px;
    border-radius: 20px;
    border: none;
    font-size: 15pt;
    font-weight: 700;
    transition: 1s;
    outline: none;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  /* .login-btn:hover{
    transform: scale(1.03);
    letter-spacing: 4px;
    background-color: #7FFFD4;
    color: #1E90FF;
  } */
  