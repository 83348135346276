@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');



.block{
    /* text-align: center; */
    display: flex;
    justify-content: center;
    padding: 40px;
    font-family: 'Poppins',sans-serif;
    color: rgb(23, 23, 23);

}

.title{
    font-family: 'Poppins',sans-serif;
    color: rgb(23, 23, 23);
    display: block;
    justify-content: center;
    text-align: center;
    margin:auto;
    
}
.left-div{
    width:50%;
    height: 100px;
    float: left;
}

.right-div{
    margin-left: 50%;
    height: 100px;
}

.item-right{
    margin-right:20%;
    margin-top:5%
}
.item-left{
    margin-left: 20%;
    margin-top:5%
}


