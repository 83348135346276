@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');



.atomlogo{
    /* text-align: center; */
    /* display: flex; */
    /* justify-content: center; */
    /* height: 110px; */
    /* padding: 2%; */
    /* justify-content: center; */
    font-family: 'Poppins',sans-serif;
    color: azure;
    font-size: 20px;
    padding: auto;
    margin-top: 0.27%;
    text-shadow: 0 0 20px #160237;

}

.navtext{
    /* text-align: center; */
    /* display: flex; */
    /* justify-content: center; */
    /* height: 110px; */
    /* padding: 2%; */
    justify-content: center;
    font-family: 'Poppins',sans-serif;
    font-weight:normal;
    text-transform: uppercase;
    text-shadow: 0 0 15px #8e54e9;
    transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.navtext:hover {
    color: #111;
    transform: scale(1.1);
    text-shadow: none;
  }
