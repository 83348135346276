@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');



.digimage{
    position: fixed;
    left: 0;
    right: 0;
    z-index: -1;
    display: block;
    background-image: url('../assets/digestive.svg');
    background-repeat: no-repeat;
    background-size: 100% 80%;
    background-position:center 100px;
    height: 100vh;
    /* width: 1200px;
    height: 800px; */
    -webkit-filter: blur(0.7px);
    /*-moz-filter: blur(1px);
    -o-filter: blur(1px);
    -ms-filter: blur(1px);
    filter: blur(1px); */
    opacity: 0.7;
}

.images{
    margin-left: 22%;
    display: block;
    display: inline-flex;
    flex-wrap: wrap;
}

.container{
    padding:20px;
}


.img_container{
    padding: 20px;
    text-align: center;
    border: 2px;
    width: fit-content;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 5%;
    
}
.body{
    font-family: 'Poppins',sans-serif;
}
/* 
.uptype{
    display: flex;
    justify-content: space-evenly ;
    flex-direction: column;
} */
.viewer{
    text-align: center;
    align-items: center;
    display: inline-block;
}
.grid-container-element { 
    padding: 20px;
    display: grid; 
    grid-template-columns: 3fr 3fr; 
    grid-gap: 40%; 
    width: 100%; 
} 
.grid-child-element { 
    margin: 10px; 
}

.molstar{
    align-items: center;
    justify-content: center;
    display: flex;
}

.textprot{
    font-family: 'Poppins',sans-serif;
}

.main-contianer{
    padding: 20px;
}
