@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.tasks{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.description{
    font-family: Poppins, sans-serif;
    margin: 20px;
}

.result-title{
    font-family: Poppins, sans-serif;
    padding: 20px;
}
.result-box{
    /* margin: 10px; */
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 2%;
    margin-bottom: 2%;
    border-radius: 5px;
    height: 100%;
    background-color: aliceblue;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}